import { baseurl, reportUrl } from "./authServices";
import { postFunc, getFunc, getFuncForDownload } from "./httpService";
import { qs } from "./authServices";

export const orderUrl = process.env.REACT_APP_BASE_ORDER_URL;
// create vendor
export const createVendorService = ({ payload }) => {
  const { vendorInfo, vendorAdmin } = payload;
  const objectToSend = { vendorAdmin, vendorInfo };
  return postFunc(`${baseurl}userprofiling/createvendor`, objectToSend);
};

// get basic profile info of customer user
export const getBasicInfoService = () => {
  return getFunc(`${baseurl}Auth/GetBasicProfile`);
};

// edit basic profile info of ISW ADMIN
export const editBasicInfoService = ({ payload }) => {
  return postFunc(`${baseurl}userprofiling/editprofile`, payload);
};

// edit payment profile info of ISW ADMIN
export const updatePaymentCodeInfoService = ({ payload }) => {
  return postFunc(`${orderUrl}Vendor/UpdateVendor`, payload);
};

// create customer user
export const createCustomerService = ({ payload }) => {
  return postFunc(`${baseurl}ISW/CreateCustomer`, payload);
};

// create system user

export const createSystemUserService = ({ payload }) => {
  return postFunc(`${baseurl}ISW/CreateSuperAdmin`, payload);
};

// get system users
export const getSystemUsersService = ({ payload }) => {
  const { pageIndex, pageSize, SearchKeyword } = payload;
  const objectToSend = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    userType: 2,
    filterBy: SearchKeyword
  };
  return postFunc(`${baseurl}ISW/GetAllUsers`, objectToSend);
};

// get audit logs
export const getAudtiLogsService = ({ payload }) => {
  return getFunc(`${baseurl}ISW/GetAuditlogs?${qs.stringify(payload)}`);
};

export const downloadAuditLogsService = ({ payload }) => {
  return getFuncForDownload(
    `${baseurl}ISW/GetAuditlogs?${qs.stringify(payload)}`
  );
};

// get report logs
export const getReportLogsService = ({ payload }) => {
  return getFunc(
    `${reportUrl}v1/report/customers/order/all?${qs.stringify(payload)}`,
    payload
  );
};

// get
export const getReportLogsDownloadPDFService = ({ payload }) => {
  return getFuncForDownload(
    `${reportUrl}v1/report/customers/order/generate/pdf?${qs.stringify(
      payload
    )}`
  );
};

export const getReportLogsDownloadExcelService = ({ payload }) => {
  return getFuncForDownload(
    `${reportUrl}v1/report/customers/order/generate/excel?${qs.stringify(
      payload
    )}`
  );
};

// bulk upload user types
export const bulkUploadService = ({ payload: { payloadToSend } }) => {
  return postFunc(`${baseurl}ISW/UploadUsers`, payloadToSend);
};

export const getAllUsersService = ({ payload }) => {
  return postFunc(`${baseurl}ISW/Getcustomerslist`, payload);
};

export const getCustomersService = ({ payload }) => {
  return postFunc(`${baseurl}ISW/GetCustomersList`, payload);
};

export const getVendorsService = ({ payload }) => {
  return postFunc(`${baseurl}ISW/GetVendorList`, payload);
};

export const getFleetsService = ({ payload }) => {
  return postFunc(`${baseurl}ISW/GetFleetOwnerList`, payload);
};

// get getCustomerUsersService
export const getCustomerUsersService = ({ payload }) => {
  return getFunc(
    `${baseurl}ISW/getcustomeruser?${qs.stringify(payload)}`,
    payload
  );
};
// get getCustomerInfoService
export const getCustomerInfoService = ({ payload: { id } }) => {
  return getFunc(`${baseurl}ISW/getcustomerinfo?customerId=${id}`);
};

export const getVendorUsersService = ({ payload }) => {
  return getFunc(
    `${baseurl}ISW/getvendoruser?${qs.stringify(payload)}`,
    payload
  );
};

export const getVendorInfoService = ({ payload: { id } }) => {
  return getFunc(`${orderUrl}Vendor/GetVendorInfo?vendorId=${id}`);
};

export const getFleetUsersService = ({ payload }) => {
  return getFunc(
    `${baseurl}ISW/getfleetowneruser?${qs.stringify(payload)}`,
    payload
  );
};

export const getFleetInfoService = ({ payload: { id } }) => {
  return getFunc(`${baseurl}ISW/getfleetownerinfo?fleetOwnerId=${id}`);
};
