import React, { useState } from "react";
import { SearchSvg } from "../assets/Svgs/Svgs";
import "../assets/stylesheet/Style.css";

const MenuBar = ({ title = '', currentText = '', onChangeHandler, onSubmitHandler }) => {
  const [searchText, setSearchText] = useState(currentText);
  const hasSearch = (typeof onChangeHandler === 'function' && typeof onSubmitHandler === 'function');

  const handleBlurOrChange = (e) => {
    if (hasSearch) {
      setSearchText(e.target.value);
      onChangeHandler(e);
    }
  }

  return (
    <div className="menu-bar">
      <div className="menu-link" /* style={{borderBottomWidth: 0.5, borderBottomColor: "#e5e5e5", borderBottomStyle: "solid"}} */>
        <span className="active">{title || 'Dashboard'}</span>
        {hasSearch && <div className="menu-input-container">
          <input 
            type="text" 
            value={searchText} 
            onChange={handleBlurOrChange}
            onBlur={handleBlurOrChange}
            placeholder="Search..." 
            className="menu-input"
          />
          <i className="input-icon" onClick={onSubmitHandler}><SearchSvg /></i>
        </div>}
      </div>
    </div>
  )
};

export default MenuBar;