import React from "react";
import BgLoader from "./reuse/backgroundLoader";
import ROUTE_PATHS, { url } from "./route";

export class RerouteToContract extends React.Component {
  componentDidMount() {
    window.location.href = process.env.REACT_APP_FRONT_CONTRACT_URL;
  }

  render() {
    return <BgLoader message="Redirecting..." />;
  }
}

export class RerouteToQtellerFuel extends React.Component {
  componentDidMount() {
    if (["VENDOR_ADMIN", "VENDOR_USER", 'VENDOR_SUPER_ADMIN', 'LOGISTIC', 'PROCUREMENT'].includes(this.props.role)) {
      window.location.href = process.env.REACT_APP_FRONT_VENDOR_URL + '/vendor';
    } else if (["CUSTOMER_ADMIN", "CUSTOMER_USER"].includes(this.props.role)){
      window.location.href = process.env.REACT_APP_FRONT_CUSTOMER_URL + '/customer';
    } else if (["FLIT", 'FLEET'].includes(this.props.role)) {
      window.location.href = process.env.REACT_APP_FRONT_FLEET_URL + '/fleet';
    } else if (["SUPER_ADMIN", 'OWNER_USER'].includes(this.props.role)) {
      window.location.href = `${url}dashboard`;
    } else {
        // window.location.href = '/?d=isw';
        window.location.href = ROUTE_PATHS["quick-energy-signin"]
    }
  }

  render() {
    return <BgLoader message="Redirecting..." />
  }
}

export class RerouteOthers extends React.Component {
  componentDidMount() {
    // window.location.href = "#/404";//change to other modules
    // window.location.reload();
    window.location.href = '/logout';
  }

  render() {
    // return <>No access</>;
    return <BgLoader message="Reloading..." />;
  }
}