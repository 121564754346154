import React, { useEffect, useRef } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import LandingButton from '../../component/landing-button';
import ProductNav from '../../component/product-nav/product-nav';

import PumpIcon from '../../../assets/images/gasoline-pump.svg';
import OilIcon from '../../../assets/images/oil.svg';
import CotractIcon from '../../../assets/images/contract.svg';

import phoneIcon from '../../../assets/images/phone.svg';
import PenIcon from '../../../assets/images/pen.svg';
import CardIcon from '../../../assets/images/integrated-multichannel.svg';
import InventoryIcon from '../../../assets/images/inventory.svg';
import RemoteIcon from '../../../assets/images/remote.svg';
import WireLessIcon from '../../../assets/images/wireless.svg';
import QualityIcon from '../../../assets/images/quality.svg';
import OnlineIcon from '../../../assets/images/online.svg';
import FleetMgtIcon from '../../../assets/images/fleet_management.svg';
import LimitMgtIcon from '../../../assets/images/limit_management.svg';
import OnlineAccessIcon from '../../../assets/images/online_access.svg';
import ContractAdminIcon from '../../../assets/images/contract_admin.svg';
import ApiInteIcon from '../../../assets/images/api_integration.svg';
import AccessReIcon from '../../../assets/images/access_recon.svg';
import BulkOrderIcon from '../../../assets/images/bulk_order.svg';
import BulkCreateIcon from '../../../assets/images/bulk_creation.svg';
import TruckIcon from '../../../assets/images/truck_tracking.svg';
import MobileIcon from '../../../assets/images/mobile_app.svg';
import ScheduleIcon from '../../../assets/images/schedule_manage.svg';
import LocationIcon from '../../../assets/images/location.svg';
import ClockIcon from '../../../assets/images/clock.svg';
import IdIcon from '../../../assets/images/id.svg';
import ArrowIcon from '../../../assets/images/arrow.svg';



export const LandingPage = () => {
  const { handleShowLeadForm} = useOutletContext();
  const productRef = useRef();
  const { pathname } = useLocation();

  useEffect(()=>{
     const path = localStorage.getItem("prev-page")
      if(path){
        scroll();
        localStorage.removeItem("prev-page");
      }
  },[pathname])

  const scroll=()=>{
    document.body.scrollTo({
      top: productRef.current.offsetTop + 180,
      behavior: 'smooth',
   });
  }

  const handleSalesClick = (e) => {
    e.preventDefault();
    handleShowLeadForm();
  }

  return (
    <div className="landing-page-container">
      <div className="hero-section">
        <div className="cap-section">
          <div className="vwrap"> 
          <ul className="vmove">
           <h1 className="head-caption h-200">
             <br className="d-desktop d-tab"/>
             Digitize Your Oil & Gas Business for <br className="d-mobile" />Growth and Efficiency</h1>
          <h1 className="head-caption h-200">Optimize your operations with automated<br className="d-mobile" /> diesel order and delivery management</h1>
          <h1 className="head-caption h-200">Activate detailed monitoring & comprehensive view of<br className="d-mobile" /> transactions across all outlets</h1>
          <h1 className="head-caption"><br className="d-desktop d-tab"/>Power your business with easy<br className="d-mobile" /> access to Oil & Gas Service Providers</h1>
          </ul>
          </div>
           <span className="sub-caption">
           Seamless Automation for  Forecourt Monitoring, Multi-Channel Payments and<br className="d-mobile" /> Fleet Management; enabling convenience, transparency and growth
           </span>
          <LandingButton classname="sub-hero-button hero-button" onclick={scroll} text="View Solutions" />
        </div>
      </div>
      <div className="land-section" id="product-nav" ref={productRef}>
          <div className="sol-section mt-5" style={{
            flexDirection: 'column',
          }}>
            <h2 className="sol-cap pb-2" style={{
              color: '#479FC8'
            }}>Leverage Technology to Scale your 
            <br className="d-mobile"/> Energy Business</h2>
            <span>All in one platform for automating recurring processes,
              built to enable and facilitate seamless value exchange between
              oil & gas businesses and the people they serve. Interswitch Energy
              provides transparency, security and ease for the efficient management
              of your energy business.</span>
          </div>
          <ProductNav />
      </div>
      <div className="row">
        <div className="col-lg-6 col-sm-12 benefit-left">
          <div className="">
            <h3 className="benefit-cap">Limitless access, endless benefits</h3>
            <ul>
              <li><span className="orange-circle"></span><p>Activate self-service payment option via multiple payment channels at your outlets </p></li>
              <li><span className="orange-circle"></span>Deliver amazing customer experience every day </li>
              <li><span className="orange-circle"></span>Effectively monitor sales from multiple outlets </li>
              <li><span className="orange-circle"></span>Integrate third-party platforms seamlessly </li>
              <li><span className="orange-circle"></span>Access timely & relevant information real-time</li>
              <li><span className="orange-circle"></span>Grow your revenue with increased demand </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 benefit-right">

        </div>
      </div>
      <div className="sol-section bg-sm-white mb-0">
        <h2 className="sol-cap">
          A single trusted and reliable trade platform for the <br className="d-mobile"/>Energy sector
        </h2>
      </div>
      <div className="row product-section">
        <div className="col-lg-4 col-md-12 col-sm-12 m-0 p-0">
          <span className="icon-circle">
            <img src={PumpIcon} alt="Forecourt Automation" />
          </span>
          <h1 className="product-name">Forecourt Automation</h1>
          <p className="product-description">The Forecourt Automation Solution is the automation
            of the pumps for the purpose of controlling & managing pump
            operations and sales at the retail forecourt.
          </p>
          <LandingButton datatestid="forcort" classname="icon-button" onclick={handleSalesClick} text="Talk to Sales" icon={phoneIcon} />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 m-0 dpl">
          <ul className="feature-list mt-2">
            <li className="feature-list-item"><img alt="Customized multi-level real-time reporting" src={PenIcon} /><span>Customized multi-level real-time reporting</span></li>
            <li className="feature-list-item"><img alt="ntegrated Multichannel Payment System" src={CardIcon} /><span>Integrated Multichannel Payment System</span></li>
            <li className="feature-list-item"><img alt="Inventory management" src={InventoryIcon} /><span>Inventory management</span></li>
            <li className="feature-list-item"><img alt="Remote management of stations" src={RemoteIcon} /><span>Remote management of stations</span>
            </li>
          </ul>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 m-0 p-0 prod-sec-right">
          <ul className="feature-list mt-2">
            <li className="feature-list-item"><img alt="Wireless setup & deployment" src={WireLessIcon} /><span>Wireless setup & deployment </span></li>
            <li className="feature-list-item"><img alt="State and quality of products" src={QualityIcon} /><span>State and quality of products</span></li>
            <li className="feature-list-item"><img alt="Online real-time monitoring" src={OnlineIcon} /><span>Online real-time monitoring</span></li>
          </ul>
        </div>
      </div>
      <div className="row product-section contract">
        <div className="col-lg-4 col-md-12 col-sm-12 m-0 p-0">
          <span className="icon-circle cm">
            <img src={CotractIcon} alt="Contract Management" />
          </span>
          <h1 className="product-name">Contract Management</h1>
          <p className="product-description">The Contract Management Solution is a platform that
            automates the special contract between Oil Marketers & corporates for AGO
            & PMS purchase across retail outlets.
          </p>
          <LandingButton datatestid="contract" classname="icon-button" onclick={handleSalesClick} text="Talk to Sales" icon={phoneIcon} />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 m-0 mt-2 dpl">
          <ul className="feature-list">
            <li className="feature-list-item mt-4"><img src={FleetMgtIcon} alt="Fleet management
" /><span>Fleet management
              </span></li>
            <li className="feature-list-item"><img src={LimitMgtIcon} alt="Limit management" /><span>Limit management</span></li>
            <li className="feature-list-item"><img src={OnlineAccessIcon} alt="online access" /><span>Online access to real-time reporting</span></li>
          </ul>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 m-0 p-0 prod-sec-right">
          <ul className="feature-list mt-4">
            <li className="feature-list-item"><img src={ContractAdminIcon} alt="Contract administration management" /><span>Contract administration management</span></li>
            <li className="feature-list-item"><img src={ApiInteIcon} alt="API integration to 3rd party ERP" /><span>API integration to 3rd party ERP</span></li>
            <li className="feature-list-item"><img src={AccessReIcon} alt="Settlement & Reconciliation" /><span>Settlement & Reconciliation</span></li>
          </ul>
        </div>
      </div>
      <div className="row product-section">
        <div className="col-lg-4 col-md-12 col-sm-12 m-0 p-0">
          <span className="icon-circle qe">
            <img src={OilIcon} alt="Quick Energy" />
          </span>
          <h1 className="product-name">QuickEnergy</h1>
          <p className="product-description">QuickEnergy is a retail distribution platform 
          that manages diesel delivery to corporates & consumers, enabling you to receive and track orders digitally,
           thereby increasing your efficiency, and reducing your delivery time cycle.
          </p>
          <LandingButton icon={ArrowIcon} classname="icon-button" to={`${process.env.REACT_APP_FRONT_CUSTOMER_URL}/customer/signup`} text="Get Started" />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 m-0 dpl">
          <ul className="feature-list mt-4">
            <li className="feature-list-item"><img src={BulkOrderIcon} alt="Bulk order management
" /><span>Bulk order management
</span></li>
            <li className="feature-list-item"><img src={BulkCreateIcon} alt="Bulk order creation and approvals" /><span>Bulk order creation and approvals</span></li>
            <li className="feature-list-item"><img src={TruckIcon} alt="Truck tracking" /><span>Truck tracking</span></li>
            <li className="feature-list-item"><img src={MobileIcon} alt="Driver mobile app
" /><span>Driver mobile app
</span>
            </li>
          </ul>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 m-0 p-0 prod-sec-right">
          <ul className="feature-list mt-4">
            <li className="feature-list-item"><img src={ScheduleIcon} alt="Schedule management" /><span>Schedule management system</span></li>
            <li className="feature-list-item"><img src={LocationIcon} alt="Location administration" /><span>Location administration and GPS mapping</span></li>
            <li className="feature-list-item"><img src={ClockIcon} alt="Real time monitoring" /><span>Real time monitoring</span></li>
            <li className="feature-list-item"><img src={IdIcon} alt="Fleet and driver management" /><span>Fleet and driver management</span></li>
          </ul>
        </div>
      </div>
    </div>);
}
export default LandingPage;