import {qs} from "./authServices";
import {getFunc} from "./httpService";
const baseurlOrder = process.env.REACT_APP_BASE_ORDER_URL ?
process.env.REACT_APP_BASE_ORDER_URL :
    "http://uber-for-diesel-ordermgmt-service-uat.k8.isw.la/u4do/api/"
    //: "https://feulorder.azurewebsites.net/api/";
const baseurl = process.env.REACT_APP_BASE_AUTH_URL ?
process.env.REACT_APP_BASE_AUTH_URL :
    "http://uber-for-diesel-auth-service-uat.k8.isw.la/u4da/api/"

// get customer stats
export const getISWOrderStatsService = ({ payload }) => {
    return getFunc(
        `${baseurlOrder}Statistics/GetISWOrderStatistics?${qs.stringify(
            payload
        )}`
    );
};

export const getISWProfileStatsService = () => {
    return getFunc(`${baseurl}Statistics/GetISWProfileStatistics`);
};

export const getLocationGraphDataService = payload => {
    return getFunc(
        `${baseurlOrder}Statistics/GetISWCustomerOrderLocationVisualizerStatistics?${qs.stringify(
            payload
        )}`
    );
};
export const getCompanyGraphDataService = payload => {
    return getFunc(
        `${baseurlOrder}Statistics/GetISWCustomerOrderbyCompanyNameVisualizerStatistics?${qs.stringify(
            payload
        )}`
    );
};
export const getVendorGraphDataService = payload => {
    return getFunc(
        `${baseurlOrder}Statistics/GetISWTotalProductPurchasedbyVendorVisualizerStatistics?${qs.stringify(
            payload
        )}`
    );
};
