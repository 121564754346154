import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Spin, Radio, DatePicker } from "antd";
import { Actions } from "../redux-flow/actions/_index";
import Sidebar from "../reuse/sidebar";
import BaseComponent from "../reuse/baseComponent";
import { calcDate, calcTime, convertRole } from '../reuse/util';

import moment from 'moment';
import MenuBar from "../reuse/MenuBar";

import FilterIcon from '../assets/images/filter.svg';
import { ExportSVG } from "../assets/Svgs/Svgs";
import { PropsContext } from "../reuse/PropsContext";

class Audits extends BaseComponent {
    state = {
        currentPage: 1,
        dataPerPage: 10,
        SearchKeyword: "",
        action: "",
        module: "",
        fromDate: moment(new Date(moment().subtract(365, 'days').format('LLLL'))),
        toDate: moment(new Date(moment().subtract(0, 'days').format('LLLL'))),
        format: '',
    };

    componentDidMount() {
        const { currentPage: pageIndex, dataPerPage: pageSize, fromDate, toDate } = this.state;
        this.fetch({ pageIndex, pageSize, fromDate, toDate });
    }

    fetch = ({
        pageIndex = 1,
        pageSize = 10,
        SearchKeyword = "",
        action = "",
        module = "",
        fromDate = this.state.fromDate,
        toDate = this.state.toDate,
    }) => {
        this.props.dispatch(
            Actions.getAuditLogs({
                filterBy: SearchKeyword,
                pageIndex,
                pageSize,
                action,
                module,
                fromDate :  moment(fromDate).format('YYYY-MM-DD'),
                toDate: moment(toDate).format('YYYY-MM-DD')
            })
        );
    };

    downloadAudit = ({
        SearchKeyword = "",
        action = "",
        module = "",
        fromDate,
        toDate,
        format='',
    }) => {
        this.props.dispatch(
            Actions.downloadAuditLog({
                filterBy: SearchKeyword,
                action, 
                module,
                fromDate: moment(fromDate).format('YYYY-MM-DD'),
                toDate: moment(toDate).format('YYYY-MM-DD'),
                format
            })
        );
    };

    handleTableChange = (page, pageSize) => {
        const { dataPerPage, SearchKeyword, action, module } = this.state;

        this.setState({
            currentPage: page
        });

        this.fetch({
            pageIndex: page,
            pageSize: dataPerPage,
            action,
            module,
            SearchKeyword
        });
    };

    getBySearch = (e, empty) => {
        e.preventDefault();
        const { dataPerPage: pageSize, SearchKeyword } = this.state;
        this.setState({
            currentPage: 1,
            SearchKeyword: empty ? "" : SearchKeyword
        });
        this.props.dispatch(
            Actions.getAuditLogs({
                pageIndex: 1,
                pageSize,
                filterBy: empty ? "" : SearchKeyword
            })
        );
    };

   setModule=(e)=>{
    const { pageIndex, pageSize, fromDate, toDate, action, SearchKeyword} = this.state;
       this.setState({ module : e},()=>{
           this.fetch({ pageIndex, pageSize, action, module: e, fromDate, toDate, SearchKeyword })
       })
   }

   setAction=(e)=>{
    const { pageIndex, pageSize, fromDate, toDate, module,  SearchKeyword } = this.state;
       this.setState({ action : e},()=>{
           this.fetch({ pageIndex, pageSize, action : e, module, fromDate, toDate, SearchKeyword })
       })
   }

    handleExportExcel = async () => {
       let format = 'xlsx';
       const {
        SearchKeyword,
        action,
        module,
        fromDate,
        toDate} = this.state;
        this.downloadAudit({SearchKeyword, action, module, fromDate, toDate, format  })
    }

    onCalendarChange = (e) => {
        const { pageIndex, module, action, SearchKeyword } = this.state;
        this.setState({fromDate : moment(e[0]) })
        if(e[1]){
            this.setState({ toDate:moment(e[1])}, ()=>{
                this.fetch({action, module, fromDate: moment(e[0]), toDate: moment(e[1]), pageIndex, SearchKeyword});
           })
        }
    }


    render() {
        const { SearchKeyword, fromDate, toDate } = this.state;
        const { total, audits } = this.props;
        const { loading, toggleModal, status } = this.context;
        return (
            <Fragment>
                <div className="isw-mainLayout1">
                    <Sidebar
                        search={this.state.SearchKeyword}
                        status={status}
                        toggleModal={toggleModal}
                        resetSearch={this.getBySearch}
                    />
                    <div />
                    {/* <Spin tip="Please wait..." spinning={loading}> */}
                    <div className="isw-content--wrapper">
                        <MenuBar title='Audit Trail'
                            currentText={SearchKeyword}
                            onChangeHandler={this.handleSearch}
                            onSubmitHandler={this.getBySearch}
                        />
                        <div>
                            <div className="content" id="content-body">
                                <div className="container-fluid container-limited">
                                    <Spin spinning={loading} tip="Please wait">
                                        <div className="row mr-0">
                                        <div className="col-12 pr-0 mt-1">
                                                <span className="filter-tags-desc pr-2">Filters:</span>
                                                <Radio.Group style={{
                                                    flexDirection: 'row',
                                                    display: 'inline-block'
                                                }} defaultValue="" buttonStyle="solid" onChange={(e) => this.setAction(e.target.value)}>
                                                    <Radio.Button value="">ALL</Radio.Button>
                                                    <Radio.Button value={1}>CREATE</Radio.Button>
                                                    <Radio.Button value={2}>DELETE</Radio.Button>
                                                    <Radio.Button value={3}>EDIT</Radio.Button>
                                                    <Radio.Button value={4}>APPROVE</Radio.Button>
                                                    <Radio.Button value={5}>REJECT</Radio.Button>
                                                    <Radio.Button value={6}>DISABLE</Radio.Button>
                                                    <Radio.Button value={7}>ACTIVATE</Radio.Button>
                                                    <Radio.Button value={8}>ASSIGN</Radio.Button>
                                                </Radio.Group>
                                            </div>
                                        <div className="col-8">
                                            {/* <span className="filter-tags-desc pr-2">Module Filters:</span>
                                                <Radio.Group style={{
                                                    flexDirection: 'row',
                                                    display: 'inline-block'
                                                }} defaultValue="" buttonStyle="solid" onChange={(e) => this.setModule(e.target.value)}>
                                                    <Radio.Button value="">ALL</Radio.Button>
                                                    <Radio.Button value={1}>AUTH</Radio.Button>
                                                    <Radio.Button value={2}>ORDER</Radio.Button>
                                                </Radio.Group> */}
                                            </div>
                                            <div className="col-md-4 pr-1 mt-md-1 mt-2">
                                                <DatePicker.RangePicker
                                                    defaultValue={[fromDate, toDate]}
                                                    onCalendarChange={this.onCalendarChange}
                                                    format={"DD-MMMM-YYYY"}
                                                    allowClear={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                        
                                            <div className="col-3 offset-md-9">
                                                <div
                                                    className="contract-export-btn mt-2"
                                                    style={{ float: "right" }}
                                                >
                                                    <span onClick={this.handleExportExcel} className="text-white">
                                                        Export CSV <ExportSVG />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div
                                                    className="trail menu-link pt-0"
                                                    style={{ marginBottom: 0, paddingBottom: 4 }}
                                                >
                                                    <span
                                                        style={{
                                                            color: "#000000",
                                                            borderBottomColor: "#00425F",
                                                            borderBottomWidth: 3,
                                                            borderBottomStyle: "solid",
                                                        }}
                                                    >
                                                        Audit Trail
                                                    </span>
                                                </div>
                                                <div className="table-container pb-4">
                                                    <table className="table table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan="2">
                                                                    Name
                                                                </th>
                                                                <th colSpan="1">
                                                                    Role Name
                                                                </th>
                                                                <th colSpan="2">
                                                                    Email Address
                                                                </th>
                                                                <th colSpan="3">
                                                                    Description
                                                                </th>
                                                                <th colSpan="2">
                                                                    Action
                                                                    <img className='ml-1 mb-1' src={FilterIcon} alt="filter icon" />
                                                                </th>
                                                                <th colSpan="2">
                                                                    Date
                                                                    <img className='ml-1 mb-1' src={FilterIcon} alt="filter icon" />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {audits.length > 0 ? audits.map((audit, index) => {
                                                                return (<tr key={`${index}-${audit.date}`}>
                                                                    <td colSpan="2">{audit.userFullName}</td>
                                                                    <td colSpan="1">{convertRole(audit.roleName)}</td>
                                                                    <td colSpan="2">{audit.userName}</td>
                                                                    <td colSpan="3" style={{
                                                                        width: '30%'
                                                                    }}>{audit.message}</td>
                                                                    <td colSpan="2">{audit.actionName}</td>
                                                                    <td colSpan="2">{calcDate(audit.date)} <br></br>
                                                                       {calcTime(audit.date)}</td>
                                                                </tr>)
                                                            }) :
                                                                <tr><td colSpan="12">{this.renderTableAbnormality("No Audit Trail available yet.")}</td></tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                    {this.renderPagination(
                                                        total
                                                        // pageConfig.pageIndex,
                                                        // pageConfig.pageSize,
                                                        // this.handlePageChange
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </Spin> */}
                </div>
            </Fragment>
        );
    }
}
Audits.contextType = PropsContext;

const mapStateToProps = ({ getAuditLogs_reducer }) => {
    const { audits, total } = getAuditLogs_reducer;
    return {
        audits,
        total
    };
};

export default connect(mapStateToProps)(Audits);
