import React, {Component, Fragment} from "react";
import Joi from "joi-browser";
import {InputField as Input} from "./input";
import {Button, IconButton} from "@material-ui/core";
import {Icon} from "antd";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TextField from "@material-ui/core/TextField";

export const SAMPLE_FILE_URL_METHOD = (path) => {
  if(path){
    return path;
  }
  else return "";
}

export const SAMPLE_FILE_URL = SAMPLE_FILE_URL_METHOD(process.env.REACT_APP_SAMPLE_UPLOAD_USER_FILE);

export const _handleInputEnter = (e) => {
  const {keyCode, target} = e;
  if (keyCode === 13) {
    const form = target.form;
    const index = Array.prototype.indexOf.call(form, target);
    e.preventDefault();
    form.elements[index].blur();
    return document.querySelector("[data-buttontarget]").click();
  }
};
class FormLayout extends Component {
  state = {
    data: {},
    styles: {},
    errors: {},
    selectedValue: "",
    file: {},
    validFile: null,
    uploadedFiles: [],
  };

  render(){
  return(<Fragment>
         <input onBlur={this.handleBlur} data-testid="handle-blur-test" />
      </Fragment>)
  }
 
  validate = () => {
    const options = {abortEarly: false};
    const {error} = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({name, value}) => {
    const obj = {[name]: value};
    const schema = {[name]: this.schema[name]};
    const {error} = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) return;

    this.submitForm();
  };

  handleChange = ({currentTarget: input}) => {
    const errors = {...this.state.errors};
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = {...this.state.data};
    data[input.name] = input.value;
    this.setState({data, errors});
  };

  uploadFile = (e) => {
    const uploadedFile = e.target.files[0];
    return uploadedFile
      ? this.setState({
          file: uploadedFile,
          validFile:
            uploadedFile.name.endsWith(".xls") ||
            uploadedFile.name.endsWith(".xlsx")
              ? true
              : false,
        })
      : null;
  };

  handleBlur = ({currentTarget: {name, value}}) => {
    const styles = {...this.state.styles};
    const style = value === "" ? "" : " form-field--is-filled";
    styles[name] = style;
    this.setState({styles});
  };

  handleFocus = ({currentTarget: input}) => {
    const styles = {...this.state.styles};
    const style = " form-field--is-active";
    styles[input.name] = style;
    const errors = {...this.state.errors};
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState({styles, errors});
  };

  renderInput = (name, label, required, myStyle = "", type = "text") => {
    const {data, errors, styles} = this.state;
    return (
      <Input
        className={`${myStyle} mb-3 ${
          styles !== undefined ? styles[name] : ""
        } ${errors[name] !== "" && errors[name] !== undefined ? "error" : ""}`}
        type={
          name === "email" || name === "password" || name === "number"
            ? name
            : type
        }
        name={name}
        value={data[name]}
        required={required}
        label={label}
        onChange={this.handleChange}
        onKeyDown={_handleInputEnter}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        error={errors[name]}
      />
    );
  };

  renderAuthInput = (label, name, disabled, isVisible = true) => {
    return (
      <>
        <label className="input-label">{label}</label>
        <input
          type={isVisible ? "text" : "password"}
          style={{color: "#000"}}
          disabled={disabled}
          name={name}
          value={this.state.data[name]}
          onChange={this.handleInputChange}
          className="account-input"
        />
      </>
    );
  };

  renderSelect = (options) => (
    <div className="col-12">
      <div className="mb-4">
        <div className="form-select">
          <select
            className="select-text"
            onChange={(e) => this.setState({selectedValue: e.target.value})}
          >
            {options.map((option) => (
              <option value={Object.values(option)} key={Object.values(option)}>
                {Object.keys(option)}
              </option>
            ))}
          </select>
          <span className="select-highlight" />
          <span className="select-bar" />
          <label className="select-label">Select Role</label>
        </div>
      </div>
    </div>
  );

  renderBulkUploader = ({toggleRightSidebar, isLoading, checkId, valid}) => {
    const {uploadedFiles} = this.state;
    return (
      <div className="isw-content--wrapper">
        <div>
          <form className="content" id="content-body">
            <div className="container-fluid container-limited">
              <div className="row">
                <div className="col-lg-12 mx-auto">
                  <div className="isw-card">
                    {/* <div className="isw-card-header p-3 mb-4">
                                            <h3 className="isw-card-h3">
                                                New User - Bulk Upload
                                            </h3>
                                            <div className="isw-card-p">
                                                Upload excel sheet
                                            </div>
                                        </div> */}
                    <div
                      className="card-body p-3"
                      style={{
                        paddingTop: `0 !important`,
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            {/* <UserList
                                                            {...{
                                                                type: toggleRightSidebar,
                                                                el: this
                                                            }}
                                                        />
                                                        {toggleRightSidebar ===
                                                        "bulk_vendors"
                                                            ? this.renderSelect(
                                                                  checkId
                                                                      ? [
                                                                            {
                                                                                Logistics: 4
                                                                            },
                                                                            {
                                                                                Procurement: 5
                                                                            }
                                                                        ]
                                                                      : [
                                                                            {
                                                                                "Vendor Admin": 3
                                                                            }
                                                                        ]
                                                              )
                                                            : toggleRightSidebar ===
                                                              "bulk_customers"
                                                            ? this.renderSelect(
                                                                  checkId
                                                                      ? [
                                                                            {
                                                                                "Customer User": 9
                                                                            }
                                                                        ]
                                                                      : [
                                                                            {
                                                                                "Customer Admin": 8
                                                                            }
                                                                        ]
                                                              )
                                                            : null} */}

                            <div className="col-12 mb-3">
                              <TextField
                                type="file"
                                label={`Upload ${
                                  toggleRightSidebar === "bulk_vendors"
                                    ? "Vendors"
                                    : "Customers"
                                } File (Excel Only)`}
                                margin="dense"
                                style={{
                                  width: "100%",
                                }}
                                className="file-picker"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={this.uploadFile}
                              />

                              <span
                                style={{
                                  color: "#ee312a",
                                }}
                              >
                                {valid === false
                                  ? "Wrong file format. Please pick an excel (.xls or .xlsx) file!"
                                  : valid === "empty"
                                  ? "Please pick a file"
                                  : null}
                              </span>
                            </div>

                            <div className="col-12">
                              <a
                                download="Sample Excel Sheet"
                                href={SAMPLE_FILE_URL}
                              >
                                <Button
                                  style={{
                                    width: "100%",
                                  }}
                                  variant="text"
                                  color="primary"
                                  className="upload-btn"
                                >
                                  DOWNLOAD SAMPLE UPLOAD FILE
                                  <CloudDownloadIcon
                                    style={{
                                      paddingLeft: "5px",
                                    }}
                                  />
                                </Button>
                              </a>
                            </div>

                            {uploadedFiles && uploadedFiles.length !== 0 ? (
                              <div className="col-12">
                                <div className="card my-3">
                                  <div className="card-header">
                                    Upload status
                                  </div>
                                  <div className="card-body">
                                    {uploadedFiles.map(
                                      (
                                        {firstName, lastName, status},
                                        index
                                      ) => (
                                        <div
                                          key={index}
                                          className="d-flex align-items-center mb-2"
                                        >
                                          {status === "ok" ? (
                                            <i className="material-icons green mr-2">
                                              check_circle
                                            </i>
                                          ) : (
                                            <i className=" material-icons red mr-2">
                                              cancel
                                            </i>
                                          )}
                                          <span>
                                            {`${firstName}
                                                                                                        ${lastName}`}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <div className="card-footer">
                                    <p className="font-weight-bold">
                                      Total upload{" "}
                                      <span className="font-weight-normal">
                                        {uploadedFiles.length}
                                      </span>{" "}
                                    </p>
                                    <p className="font-weight-bold small mb-0">
                                      Success:{" "}
                                      <span className="font-weight-normal">
                                        {
                                          uploadedFiles.filter(
                                            (r) => r.status === "ok"
                                          ).length
                                        }
                                      </span>{" "}
                                    </p>
                                    <p className="font-weight-bold small mb-0">
                                      Failed:{" "}
                                      <span className="font-weight-normal">
                                        {
                                          uploadedFiles.filter(
                                            (r) => r.status === "failed"
                                          ).length
                                        }
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="drawer-btn-cont">
            <Button
              onClick={this._toggleRightSidebar}
              style={{
                cursor: "pointer",
              }}
              className="isw-btn  text-primary"
            >
              <span>Cancel</span>
            </Button>

            {isLoading ? (
              <Button className="dot isw-btn  text-blue" disabled>
                Saving
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </Button>
            ) : (
              <Button
                onClick={(e) =>
                  this.uploadBulk({
                    e,
                    role: this.state.selectedValue,
                    file: this.state.file,
                  })
                }
                className="isw-btn  text-blue"
              >
                <span>Save</span>
              </Button>
            )}
          </div>{" "}
        </div>
      </div>
    );
  };

  renderDrawerHeaderButton = (text) => (
    <div>
      <IconButton
        onClick={this._toggleRightSidebar}
        style={{
          cursor: "pointer",
        }}
      >
        <Icon
          type="right"
          style={{
            width: `${16}px`,
            color: "#999",
          }}
        />
      </IconButton>
      {text}
    </div>
  );

  renderDrawerSubmitButton = (loading, handler, activeText, disabledText) => (
    <Button
      onClick={loading ? null : handler}
      disabled={loading}
      data-buttontarget="final-submit-button"
      className={`isw-btn text-blue ${loading ? "dot" : ""}`}
    >
      {loading ? (
        <>
          {disabledText}
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </>
      ) : (
        <span>{activeText}</span>
      )}
    </Button>
  );

  renderButton = (label, loading, handler, id) => (
    <Button
      id={id}
      data-buttontarget="final-submit-button"
      onClick={loading ? null : handler}
      disabled={loading}
      className={`isw-btn bg-primary text-white w-100 ${
        loading ? "dot false" : "isw-btn--raised"
      }`}
    >
      {loading ? (
        <>
          Loading
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </>
      ) : (
        <span>{label}</span>
      )}
    </Button>
  );
}

export default FormLayout;
